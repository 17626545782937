import { Box, VStack, Heading, Button, Text } from "@chakra-ui/react";
import { usePageContext } from "vike-react/usePageContext";
import Link from "src/renderer/link";

export default function Page() {
  const { is404 } = usePageContext();
  if (is404) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        bg="gray.50"
        px={4}
      >
        <VStack spacing={6} textAlign="center">
          <Heading as="h1" size="2xl">
            Oops! Page Not Found
          </Heading>
          <Text fontSize="lg" color="gray.600">
            The page you're looking for doesn't exist or has been moved.
          </Text>
          <Button
            colorScheme="brand"
            size="lg"
            href='/'
            as={Link}
          >
            Back to Home
          </Button>
        </VStack>
      </Box>
    );
  }
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        bg="gray.50"
        px={4}
      >
        <VStack spacing={6} textAlign="center">
          <Heading as="h1" size="2xl">
            Something went wrong
          </Heading>
          <Text fontSize="lg" color="gray.600">
            The page you're looking could not be loaded.
          </Text>
          <Button
            colorScheme="brand"
            size="lg"
          >
            <Link href="/">
              Back to Home
            </Link>
          </Button>
        </VStack>
      </Box>
    </>
  );
}
